import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PageHeader, BannerLeft } from "../utils"

const SpecialsPage = ({ data }) => {
  const smImg = getSrc(data.smImg)
  const img = getSrc(data.img)

  return (
    <Layout>
      <Seo
        title="Specials"
        keywords={[
          `azalea bar & kitchen special offers`,
          `breakfast lunch dinner in asheville nc`,
          `restaurants near biltmore in asheville nc`,
        ]}
      />

      <PageHeader smImg={smImg} img={img}>
        <BannerLeft
          title="Specials"
          subtitle="Daily Specials Coming Soon, Fall 2024"
        ></BannerLeft>
      </PageHeader>
    </Layout>
  )
}

export default SpecialsPage

export const query = graphql`
  query {
    smImg: file(relativePath: { eq: "abk-chicken-sandwich-p-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 60
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 992
        )
      }
    }
    img: file(relativePath: { eq: "abk-chicken-sandwich-l-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 75
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 2000
        )
      }
    }
  }
`
